import { Suspense, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, Environment } from '@react-three/drei'
import { Routes, Route } from 'react-router-dom'
import NotFound from './NotFound'
import './styles.css'

function Horse(props) {
  const { nodes } = useGLTF('/horse-draco.glb')
  const ref = useRef()
  useFrame((state, delta) => {
    state.mouse.x
      ? ref.current.rotation.y += state.mouse.x * 0.005
      : ref.current.rotation.y += delta / 10
  })
  return (<mesh geometry={nodes.Horse.geometry} scale={[0.9, 0.9, 0.9]}
                position={[0, -0.5, 0]} {...props} ref={ref}>
    <meshPhysicalMaterial
      roughness={0}
      transmission={1}
      ior={1}
      envMapIntensity={100}
    />
  </mesh>)
}

const Application = () => {
  return <>
    <Canvas>
      <Suspense fallback={null}>
        <Horse />
        <Environment files='adams_place_bridge_1k.hdr' />
      </Suspense>
    </Canvas>
    <div className='middle'>
      <h2>The</h2>
      <h1>FAKE HORSE</h1>
    </div>
  </>
}

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Application />} />
      <Route path='/*' element={<NotFound />} />
    </Routes>
  )
}

export default App