import * as THREE from 'three'
import React, { Suspense, useRef, useMemo } from 'react'
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import { useGLTF, useFBO, Stage, Effects, OrbitControls } from '@react-three/drei'
import { FXAAShader } from 'three-stdlib'
import { AdditiveBlendingShader } from './shaders/AdditiveBlendingShader'
import { VolumetricLightShader } from './shaders/VolumetricLightShader'
import './styles.css'

const DEFAULT_LAYER = 0
const OCCLUSION_LAYER = 1

function Elf({ layer = DEFAULT_LAYER }) {
  const ref = useRef()
  const { nodes } = useGLTF('/horse-draco.glb')
  const material = useMemo(() => {
    if (layer === DEFAULT_LAYER) return new THREE.MeshStandardMaterial({
      color: new THREE.Color('rgb(0,0,0)'),
      roughness: 1,
      metalness: 0.9
    })
    else return new THREE.MeshBasicMaterial({ color: new THREE.Color('black') })
  }, [layer])

  useFrame((state, delta) => {
    state.mouse.x
      ? ref.current.rotation.y += state.mouse.x * 0.005
      : ref.current.rotation.y += delta / 10
  })

  return (<mesh
    geometry={nodes.Horse.geometry}
    scale={[0.9, 0.9, 0.9]}
    position={[0, -0.5, 0]}
    material={material} layers={layer} receiveShadow castShadow ref={ref}
  />)
}

function Post() {
  const { gl, camera, size } = useThree()
  const occlusionRenderTarget = useFBO()
  const occlusionComposer = useRef()
  const composer = useRef()
  useFrame(() => {
    camera.layers.set(OCCLUSION_LAYER)
    occlusionComposer.current.render()
    camera.layers.set(DEFAULT_LAYER)
    composer.current.render()
  }, 1)
  return (
    <>
      <mesh layers={OCCLUSION_LAYER} position={[0, 0, -5]}>
        <circleGeometry args={[5, 64]} />
        <meshBasicMaterial />
      </mesh>
      <Effects ref={occlusionComposer} disableGamma disableRender args={[gl, occlusionRenderTarget]}
               renderToScreen={false}>
        <shaderPass args={[VolumetricLightShader]} needsSwap={false} />
      </Effects>
      <Effects ref={composer} disableRender>
        <shaderPass args={[AdditiveBlendingShader]} uniforms-tAdd-value={occlusionRenderTarget.texture} />
        <shaderPass args={[FXAAShader]} uniforms-resolution-value={[1 / size.width, 1 / size.height]} renderToScreen />
      </Effects>
    </>
  )
}

const NotFound = () => {
  return (
    <>
      <Canvas
        gl={{ antialias: false }}
      >
        <OrbitControls />
        <Suspense fallback={null}>
          <Elf layer={OCCLUSION_LAYER} />
          <Post />
        </Suspense>
      </Canvas>
      <div className='middle'>
        <h2>Not Found</h2>
        <h1>404</h1>
      </div>
    </>
  )
}

export default NotFound