import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
const container = document.getElementById('root')
const root = createRoot(container)
import './styles.css'

const Application = () => {
  useEffect(() => {
    console.log('TFH running')
  })
  return <BrowserRouter>
      <App />
  </BrowserRouter>
}

root.render(<Application />)